<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="modelRef" @finish="onSearch">
			    <a-row>
			        <a-form-item name="title" class="ui-form-item" label="任务名称">
			            <a-input v-model:value="modelRef.title" placeholder="请输入"/>
			        </a-form-item>
			
			        <a-form-item name="userName" class="ui-form-item" label="操作人姓名">
			            <a-input v-model:value="modelRef.userName" placeholder="请输入"></a-input>
			        </a-form-item>
			    </a-row>
			
			    <a-row>
			        <a-col :span="10">
			        </a-col>
			        <a-col :span="14" style="text-align: right;">
			            <a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
			            <a-button @click="resetBtn">重置</a-button>
			        </a-col>
			    </a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :dataSource="dataList" :columns="dataColumns" rowKey="id" :pagination="pagination" :scroll="{ x: 1700 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'type'">
						{{ transType(record.type) }}
					</template>
					<template v-if="column.key === 'status'">
						{{ transStatus(record.status) }}
					</template>
					<template v-if="column.key === 'exceptionInfo'">
						<div v-if="record.exceptionInfo === 'FAILURE_REDIRECT'">第三方接口连接不通</div>
						<div v-else-if="record.exceptionInfo === 'SETTING_M'">影院ip配置不存在</div>
						<div v-else-if="record.exceptionInfo">{{ record.exceptionInfo }}</div>
						<div v-else>--</div>
					</template>
					<template v-if="column.key === 'failure'">
						<div v-if="record.failureData">
							<p>
								<a v-if="record.failureData.repeat" @click="onShowCode(record.failureData.repeat, 'repeat')">查看重复的券号</a>
							</p>
							<p>
								<a v-if="record.failureData.formatError" @click="onShowCode(record.failureData.formatError, 'formatError')">查看格式错误的券号</a>
							</p>
							<p><a v-if="record.failureData.other" @click="onShowCode(record.failureData.other, 'other')">查看第三方接口无法识别报错的券号</a></p>
						</div>
						<div v-else> -- </div>
					</template>
					<template v-if="column.key === 'cinemaName'">
						{{ record.cinemaName || '--' }}
					</template>
					<template v-if="column.key === 'spendTime'">
						{{ record.spendTime || '--' }}
					</template>
					<template v-if="column.key === 'finishTime'">
						{{ transDateTime(record.finishTime) }}
					</template>
					<template v-if="column.key === 'action'">
						<a v-if="record.status === 1 && ['generateCoupon', 'generateSnackCoupon'].indexOf(record.type) === -1" @click="onShowModal(record)">查看当前进度</a>
						<span v-else>--</span>
					</template>
				</template>
			</a-table>
			
			<a-modal v-model:visible="showModal" title="查看当前进度" width="500px">
				<template #footer>
					<a-button type="primary" @click="showModal = false">关闭</a-button>
				</template>
				<a-spin :spinning="loading">
					<a-button type="primary" style="margin-bottom: 20px;" @click="onShowModal(currentTask)">刷新</a-button>
					<div>
						<div>状态：{{ transStatus(detail.status) }}</div>
						<div>总条数：{{ detail.totalCount || 0 }}</div>
						<div>当前已完成条数：{{ detail.currCount || 0 }}</div>
					</div>
				</a-spin>
			</a-modal>
			
			<a-modal v-model:visible="showCode" :title="title">
				<template #footer>
					<a-button type="primary" @click="showCode = false">关闭</a-button>
				</template>
				<a-table :dataSource="codeList" :columns="codeColumns">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'code'">{{ record.code }}</template>
					</template>
				</a-table>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import { getImportList, getImportschedule } from '@/service/modules/export.js';
	export default {
		data() {
			return {
				loading: false,
				modelRef: {},
				searchData: {},
				dataColumns: [{
					title: '影院',
					dataIndex: 'cinemaName',
					key: 'cinemaName'
				},{
					title: '任务名称',
					dataIndex: 'title'
				}, {
					title: '类型',
					key: 'type'
				}, {
					title: '操作人姓名',
					dataIndex: 'userName'
				}, {
					title: '任务状态',
					key: 'status'
				}, {
					title: '异常原因',
					key: 'exceptionInfo'
				}, {
					title: '耗时(秒)',
					key: 'spendTime'
				}, {
					title: '完成时间',
					key: 'finishTime'
				}, {
					title: '失败的数据',
					key: 'failure'
				}, {
					title: '操作',
					key: 'action'
				}],
				dataList:[],
				pagination: {
				    showSizeChanger: true,
				    showQuickJumper: true,
				    size: "middle",
				    showTotal: (total) => {
				      return "共 " + total + " 条";
				    },
				    total: 0,
				    current: 1,
				    pageSize: 20,
				    onChange: (page) => {
				      this.pagination.current = page;
				      this.getData();
				    },
				    onShowSizeChange: (page, pageSize) => {
				      this.pagination.current = 1;
				      this.pagination.pageSize = pageSize;
				      this.getData();
				    }
				},
				showModal: false,
				currentTask: {},
				detail: {},
				title: '',
				showCode: false,
				codeList: [],
				codeColumns: [{
					title: '券号',
					key: 'code'
				}]
			}
		},
		created() {
			this.getData();
		},
		methods: {
			transStatus(val) {
				if (val === 0) return '已就绪';
				if (val === 1) return '进行中';
				if (val === 2) return '已完成';
				if (val === 3) return '异常';
			},
			transType(val) {
				if (val === 'cardImport') return '线上会员卡导入';
				if (val === 'generateCoupon') return '兑换券批量生成';
				if (val === 'voucherImport') return '兑换券导入';
				if (val === 'saleCouponImport') return '卖品券导入';
				if (val === 'generateSnackCoupon') return '卖品券批量生成';
				if (val === 'batchDelayCoupon') return '兑换券批量延期';
				if (val === 'userVoucherBatchDelay') return '兑换券信息批量延期';
				if (val === 'voucherBatchDelay') return '兑换券入券库延期';
				if (val === 'enableCoupon') return '兑换券批量启用';
				if (val === 'disableCoupon') return '兑换券批量禁用';
				if (val === 'unbundlingCoupon') return '兑换券信息批量解绑';
				if (val === 'bindUserCoupon') return '兑换券批量绑定用户';
				if (val === 'enableSaleCoupon') return '卖品券批量启用';
				if (val === 'disableSaleCoupon') return '卖品券批量禁用';
				if (val === 'unbundlingSaleCoupon') return '卖品券信息批量解绑';
				if (val === 'userSnackCouponBatchDelay') return '卖品券信息批量延期';
				if (val === 'batchDelaySnackCoupon') return '卖品券列表批量延期';
				if (val === 'snackBindUserCoupon') return '卖品券批量绑定用户';
				if (val === 'batchCouponImport') return '批次兑换券导入';
				if (val === 'batchSaleCouponImport') return '批次卖品券导入';
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getImportList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code ===200) {
						this.pagination.total = ret.data.totalCount;
						ret.data.list.forEach(item => {
							if (item.failureData) {
								item.failureData = JSON.parse(item.failureData);
							}
						})
						this.dataList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			async onShowModal(item) {
				this.currentTask = item;
				this.loading = true;
				try {
					let ret = await getImportschedule({
						taskId: item.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.detail = ret.data;
						this.showModal = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onShowCode(data, key) {
				if (key === 'repeat') {
					this.title = '重复的券号'
				}
				if (key === 'formatError') {
					this.title = '格式错误的券号'
				}
				if (key === 'other') {
					this.title = '第三方接口无法识别报错的券号'
				}
				this.codeList = data.split(',').map(item => {
					return {
						code: item
					}
				});
				this.showCode = true;
			}
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
</style>
